import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

let router = new VueRouter({
    mode: 'history', // hash
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            component: () => import('../pages/home/Home'),
            meta: { requiresAuth: true },
        },
        {
            path: '/login',
            component: () => import('../pages/login/Login'),
            meta: { guest: true },
        },
        {
            path: '/forgot-password',
            component: () => import('../pages/login/ForgotPassword'),
            meta: { guest: true },
        },
        {
            path: '/new-password',
            component: () => import('../pages/login/NewPassword'),
            meta: { guest: true },
        },
        {
            path: '/account',
            component: () => import('../pages/account/Account'),
            meta: { requiresAuth: true },
        },
        {
            path: '/cadastro-cupom',
            component: () => import('../pages/cadastro/Cupom'),
            meta: { requiresAuth: true },
        },
        {
            path: '/cadastro-produto',
            component: () => import('../pages/cadastro/Produto'),
            meta: { requiresAuth: true },
        },
        {
            path: '/cadastro-combo',
            component: () => import('../pages/cadastro/Combo'),
            meta: { requiresAuth: true },
        },
        {
            path: '/cadastro-colaborador',
            component: () => import('../pages/cadastro/Colaborador'),
            meta: { requiresAuth: true },
        },
        {
            path: '/configuracoes',
            component: () => import('../pages/configuracoes/Configuracoes'),
            meta: { requiresAuth: true },
        },
        {
            path: '/municipios',
            component: () => import('../pages/pesquisa/Municipios'),
            meta: { requiresAuth: true },
        },
        {
            path: '/bairros',
            component: () => import('../pages/pesquisa/Bairros'),
            meta: { requiresAuth: true },
        },
        {
            path: '/distancias',
            component: () => import('../pages/pesquisa/Distancias'),
            meta: { requiresAuth: true },
        },
        {
            path: '/produtos',
            component: () => import('../pages/pesquisa/Produtos'),
            meta: { requiresAuth: true },
        },
        {
            path: '/cadastro-rapido-produtos',
            component: () => import('../pages/pesquisa/CadastroRapidoProdutos'),
            meta: { requiresAuth: true },
        },
        {
            path: '/cadastro-rapido-complementos',
            component: () => import('../pages/pesquisa/CadastroRapidoComplementos'),
            meta: { requiresAuth: true },
        },
        {
            path: '/minha-assinatura',
            component: () => import('../pages/assinatura/Index'),
            meta: { requiresAuth: true },
        },
        {
            path: '/grupos',
            component: () => import('../pages/pesquisa/Grupos'),
            meta: { requiresAuth: true },
        },
        {
            path: '/complementos',
            component: () => import('../pages/pesquisa/Complementos'),
            meta: { requiresAuth: true },
        },
        {
            path: '/complementos-categorias',
            component: () => import('../pages/pesquisa/ComplementosCategorias'),
            meta: { requiresAuth: true },
        },
        {
            path: '/combos',
            component: () => import('../pages/pesquisa/Combos'),
            meta: { requiresAuth: true },
        },
        {
            path: '/complementos',
            component: () => import('../pages/pesquisa/Complementos'),
            meta: { requiresAuth: true },
        },
        {
            path: '/steps',
            component: () => import('../pages/cadastro/Steps'),
            meta: { requiresAuth: true },
        },
        {
            path: '/colaboradores',
            component: () => import('../pages/pesquisa/Colaborador'),
            meta: { requiresAuth: true },
        },
        {
            path: '/campanhas',
            component: () => import('../pages/pesquisa/Campanhas'),
            meta: { requiresAuth: true },
        },
        {
            path: '/publicos',
            component: () => import('../pages/pesquisa/Publicos'),
            meta: { requiresAuth: true },
        },
        {
            path: '/notificacoes',
            component: () => import('../pages/pesquisa/Notificacoes'),
            meta: { requiresAuth: true },
        },
        {
            path: '/unidades-medida',
            component: () => import('../pages/pesquisa/UnidadesMedida'),
            meta: { requiresAuth: true },
        },
        {
            path: '/caixa',
            component: () => import('../pages/caixa/Caixa'),
            meta: { requiresAuth: true },
        },
        {
            path: '/checkout',
            component: () => import('../pages/caixa/Checkout'),
            meta: { requiresAuth: true },
        },
        {
            path: '/relatorio-vendas',
            component: () => import('../pages/relatorio/Vendas'),
            meta: { requiresAuth: true },
        },
        {
            path: '/relatorio-vendas-produtos',
            component: () => import('../pages/relatorio/VendasProdutos'),
            meta: { requiresAuth: true },
        },
        {
            path: '/relatorio-estoque',
            component: () => import('../pages/relatorio/RelatorioEstoque'),
            meta: { requiresAuth: true },
        },
        {
            path: '/relatorio-graficos',
            component: () => import('../pages/relatorio/Graficos'),
            meta: { requiresAuth: true },
        },
        {
            path: '/vendas-detalhes',
            component: () => import('../pages/relatorio/VendasDetalhes'),
            meta: { requiresAuth: true },
        },
        {
            path: '/cupons',
            component: () => import('../pages/pesquisa/Cupons'),
            meta: { requiresAuth: true },
        },
        {
            path: '/contas-pagar-receber',
            component: () => import('../pages/pesquisa/ContasPagarReceber'),
            meta: { requiresAuth: true },
        },
        {
            path: '/clientes',
            component: () => import('../pages/pesquisa/clientes/Clientes'),
            meta: { requiresAuth: true },
        },
        {
            path: '/entregadores',
            component: () => import('../pages/pesquisa/Entregadores'),
            meta: { requiresAuth: true },
        },
        {
            path: '/categorias-imposto',
            component: () => import('../pages/pesquisa/CategoriasImposto'),
            meta: { requiresAuth: true },
        },
        {
            path: '/caixa-historico',
            component: () => import('../pages/pesquisa/CaixaHistorico'),
            meta: { requiresAuth: true },
        },
        {
            path: '/produtos-imposto',
            component: () => import('../pages/cadastro/ProdutosImposto'),
            meta: { requiresAuth: true },
        },
        {
            path: '/notas-fiscais',
            component: () => import('../pages/pesquisa/notas-fiscais/NotasFiscais'),
            meta: { requiresAuth: true },
        },
        {
            path: '/formas-pagamento',
            component: () => import('../pages/pesquisa/FormasPagamento'),
            meta: { requiresAuth: true },
        },
        {
            path: '/videos-de-ajuda',
            component: () => import('../pages/ajuda/Index'),
            meta: { guest: true },
        },
        {
            path: '/qrcode',
            component: () => import('../pages/qrcode/Index'),
            meta: { requiresAuth: true },
        },
        {
            path: '/pontos-movimentacoes',
            component: () => import('../pages/pesquisa/PontosMovimentacoes'),
            meta: { requiresAuth: true },
        },
        {
            path: '/novidades',
            component: () => import('../pages/pesquisa/Novidades'),
            meta: { requiresAuth: true },
        },
        {
            path: '/plano-anual',
            component: () => import('../pages/ofertas/PlanoAnual'),
            meta: { requiresAuth: true },
        },
        {
            path: '/plano-profissional',
            component: () => import('../pages/ofertas/PlanoProfissional'),
            meta: { requiresAuth: true },
        },
        {
            path: '/modulo-fiscal',
            component: () => import('../pages/ofertas/ModuloFiscal'),
            meta: { requiresAuth: true },
        },
        // {
        //     path: '/kanban',
        //     component: () => import('../pages/kanban/Index'),
        //     meta: { requiresAuth: true },
        // },
        {
            path: '/register',
            component: () => import('../pages/registro/Registro'),
            meta: { requiresAuth: true },
        },
        {
            path: '*',
            redirect: '/',
            meta: { requiresAuth: true },
        },
    ],
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (sessionStorage.getItem('token') == null) {
            next({
                path: '/login',
                params: { nextUrl: to.fullPath },
            });
        } else {
            // let user = JSON.parse(sessionStorage.getItem('user'))
            // if (to.matched.some(record => record.meta.is_admin)) {
            //     if (user.is_admin == 1) {
            //         next()
            //     }
            //     else {
            //         next({ name: 'userboard'})
            //     }
            // } else {
                next();
            // }
        }
    } else if (to.matched.some(record => record.meta.guest)) {
        if (sessionStorage.getItem('token') == null){
            next();
        } else {
            // next({ name: 'userboard'})
            next();
        }
    } else {
        next();
    }
});

export default router;
